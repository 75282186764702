<template>
  <div class="noticeDetailWrap">
    <div class="main">
      <p class="backWrap mb20">
        <i class="el-icon-arrow-left mr15" @click="$router.go(-1)"></i>公告详情
      </p>
      <h1 class="noticeName mb15">{{ detail.notice_name }}</h1>
      <div class="noticeSub mb40">
        <div class="subMake">
          <p class="mr25">制作日期：{{ detail.make_time }}</p>
          <p class="mr25">制作部门：{{ detail.make_faculty }}</p>
          <p>制作人：{{ detail.make_people }}</p>
        </div>
        <div><i class="el-icon-view"></i> {{ detail.read_num }}</div>
      </div>
      <div v-html="detail.content" class="contentWrap"></div>
    </div>
  </div>
</template>

<script>
import { toNoticeInfo } from "../assets/api";
export default {
  data() {
    return {
      detail: {
        notice_name: "",
        make_time: "",
        make_faculty: "",
        make_people: "",
        read_num: "",
        content: "",
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const params = {
        notice_id: this.$route.query.id,
      };
      toNoticeInfo(params).then((res) => {
        if (res.code == 0) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.noticeDetailWrap {
  position:absolute;
  left:0px;
  width:100%;
  height:calc(100% - 110px);
  // margin-top: 20px;
  background: #fff;
  padding: 20px 0 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .backWrap {
    display: flex;
    align-items: center;
    font-size: 16px;
    i {
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .noticeName {
    font-size: 24px;
  }
  .noticeSub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7b7b7b;
    font-size: 14px;
    .subMake {
      display: flex;
      align-items: center;
      .mr25 {
        margin-right: 25px;
      }
    }
  }
  .contentWrap {
    font-size: 16px;
    line-height: 36px;
  }
}
</style>
